@import "./variables.css";
@import "./elements/index.css";
@import "./util.css";

* {
	box-sizing: border-box;
	font-family: var(--font-family);
}

html,
body {
	min-height: 100%;
	background: var(--base-contrast-color);
	color: var(--text-color);
	font-size: var(--font-size);
	margin: 0;
}

/* Global animations */
@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
