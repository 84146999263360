/* Base button styles. */
button.pngc-loading {
	cursor: wait;
}

.button,
.icon-button,
.button-with-icon {
	max-height: 40px;
	padding: 12px 16px;
	border: none;
	border-radius: var(--border-radius);
	font-size: inherit;
	font-weight: 500;
	line-height: inherit;
	cursor: pointer;
	text-decoration: none;
	text-align: center;
	transition:
		background-color,
		opacity 300ms ease-in-out;
}

/* Icon button styles. */
.icon-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 40px;
	height: 40px;
}

/* Button with icon styles. */
.button-with-icon {
	display: flex;
	align-items: center;
	gap: 10px;
}

/* Success button theme. */
.success {
	background-color: var(--success-color-500);
	color: var(--base-contrast-color);
}

.success:hover,
.success:focus {
	background-color: var(--success-color-600);
}

.success:active {
	background-color: var(--success-color-700);
}

.success:disabled {
	background-color: var(--neutral-color-200);
	color: var(--neutral-dark-text-color);
	cursor: not-allowed;
}

/* Primary button theme. */
.primary {
	background-color: var(--primary-color-600);
	color: var(--primary-light-color-text);
}

.primary:hover,
.primary:focus {
	background-color: var(--primary-color-700);
}

.primary:active {
	background-color: var(--primary-color-800);
}

.primary:disabled {
	background-color: var(--neutral-color-200);
	color: var(--neutral-dark-text-color);
	cursor: not-allowed;
}

.warn {
	background-color: var(--warn-color);
	color: var(--primary-light-color-text);
}

.warn:hover,
.warn:focus {
	background-color: var(--warn-dark-text-color);
}

.warn:active {
	background-color: var(--warn-dark-text-color);
}

.warn:disabled {
	background-color: var(--neutral-color-200);
	color: var(--neutral-dark-text-color);
	cursor: not-allowed;
}

/* Inline button theme. */
.inline {
	border: none;
	background-color: transparent;
}

.inline:hover,
.inline:focus {
	background-color: var(--neutral-color-100);
}

.inline:active {
	background-color: var(--neutral-color-200);
}

.inline:disabled {
	background-color: transparent;
	color: var(--neutral-dark-text-color);
	cursor: not-allowed;
}

/* Outlined button theme. */
.outlined {
	border: 1px solid var(--neutral-color-100);
	background-color: var(--base-contrast-color);
}

.outlined:hover,
.outlined:focus {
	background-color: var(--neutral-color-100);
}

.outlined:active {
	background-color: var(--neutral-color-200);
}

.outlined:disabled {
	background-color: transparent;
	color: var(--neutral-dark-text-color);
	cursor: not-allowed;
}
