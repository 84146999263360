:root {
	--table-row-height: 48px;
	--mat-table-header-container-height: var(--table-row-height);
	--mat-table-row-item-container-height: var(--table-row-height);
	--mat-table-footer-container-height: var(--table-row-height);
}

.mat-mdc-header-row.mdc-data-table__header-row {
	background-color: var(--neutral-color-50);
}

.mat-mdc-row:hover .mat-mdc-cell {
	background-color: var(--neutral-color-50);
}

.mat-mdc-header-cell,
.mat-mdc-cell {
	text-wrap: nowrap;
}
