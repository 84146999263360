:root {
	--mdc-snackbar-container-color: var(--primary-contrast-color);
}

.mat-mdc-snack-bar-container.mat-mdc-snack-bar-container .mdc-snackbar__surface {
	box-shadow: none;
	min-width: 300px;
}

.mat-mdc-snack-bar-container.mat-mdc-snack-bar-container {
	border: 1px solid var(--neutral-color-200);
	margin: 14px;
	border-radius: 6px;
}
