:root {
	--mdc-filled-text-field-disabled-container-color: var(--neutral-color-200);
	--mdc-filled-text-field-disabled-label-text-color: var(--neutral-dark-text-color);
	--mat-form-field-disabled-input-text-placeholder-color: var(--neutral-dark-text-color);
	--mdc-filled-text-field-label-text-color: var(--base-color);
	--mdc-filled-text-field-caret-color: var(--neutral-dark-text-color);
	--mdc-filled-text-field-focus-outline-color: var(--neutral-dark-text-color);
	--mdc-filled-text-field-focus-label-text-color: var(--neutral-dark-text-color);
}

/* Customize material form field. */
.mat-mdc-form-field {
	width: 100%;
}

.mat-mdc-form-field .mat-mdc-form-field-infix {
	display: flex;
	align-items: center;
}

/* Customize default background. */
.mat-mdc-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: var(--base-contrast-color);
}

/* Customize background on material form field hover. */
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
	background-color: var(--neutral-color-50);
	opacity: 1;
}

/* Customize background on material form field focus. */
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
	background-color: var(--base-contrast-color);
}

/* Customize material form field wrapper. */
.mat-mdc-form-field .mat-mdc-text-field-wrapper {
	height: auto;
	border: 1px solid var(--neutral-color-100);
	border-radius: var(--border-radius);
}

/* Customize wrapper on material form field focus. */
.mat-mdc-form-field.mat-focused .mat-mdc-text-field-wrapper {
	border-color: var(--primary-color-600);
}

/* Customize wrapper on invalid material form field. */
.mat-mdc-form-field.mat-form-field-invalid .mat-mdc-text-field-wrapper {
	border-color: var(--warn-color);
}

/* Customize wrapper on disabled material form field. */
.mat-mdc-form-field.mat-form-field-disabled .mat-mdc-text-field-wrapper {
	border-color: var(--neutral-color-200);
}

/* Customize field underline. */
.mat-mdc-form-field .mdc-line-ripple {
	display: none;
}

/* Customize floating label. */
.mat-mdc-form-field .mdc-text-field--filled .mdc-floating-label--float-above {
	font-size: var(--font-size-small);
}

/* Customize floating label position. */
.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
	top: 27px;
}

/* Normal size. */
.mat-mdc-form-field.normal .mat-mdc-text-field-wrapper {
	height: 40px;
}

.mat-mdc-form-field.normal .mat-mdc-form-field-flex {
	height: 40px;
	align-items: center;
}

.mat-mdc-form-field.normal .mat-mdc-form-field-icon-prefix {
	height: 40px;
	max-width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mat-mdc-form-field.normal .mat-mdc-form-field-infix {
	display: flex;
	align-items: center;
	min-height: auto;
	padding-top: 22px;
}

/* Small size. */
.mat-mdc-form-field.small .mat-mdc-text-field-wrapper {
	height: 32px;
}

.mat-mdc-form-field.small .mat-mdc-form-field-flex {
	height: 32px;
	align-items: center;
}

.mat-mdc-form-field.small .mat-mdc-form-field-icon-prefix {
	height: 32px;
	max-width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mat-mdc-form-field.small .mat-mdc-form-field-infix {
	display: flex;
	align-items: center;
	min-height: auto;
	padding-top: 15px;
}

.mat-mdc-form-field.without-subscript-wrapper .mat-mdc-form-field-subscript-wrapper {
	display: none;
}

.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
	padding-left: 0;
}
