@use "@angular/material" as mat;

@include mat.core();

$dark-primary-text: var(--primary-dark-color-text);
$light-primary-text: var(--primary-light-color-text);
$dark-accent-text: var(--accent-dark-text-color);
$light-accent-text: var(--accent-light-text-color);
$dark-warn-text: var(--warn-dark-text-color);
$light-warn-text: var(--warn-light-text-color);

$pangea-primary: (
	50: rgba(239, 248, 255, 1),
	100: rgba(209, 233, 255, 1),
	200: rgba(178, 221, 255, 1),
	300: rgba(132, 202, 255, 1),
	400: rgba(83, 177, 253, 1),
	500: rgba(46, 144, 250, 1),
	600: rgba(21, 112, 239, 1),
	700: rgba(23, 92, 211, 1),
	800: rgba(24, 73, 169, 1),
	900: rgba(25, 65, 133, 1),
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $light-primary-text,
		400: $light-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
	),
);

$pangea-accent: (
	50: rgba(255, 250, 235, 1),
	100: rgba(255, 250, 235, 1),
	200: rgba(254, 223, 137, 1),
	300: rgba(254, 200, 75, 1),
	400: rgba(253, 176, 34, 1),
	500: rgba(247, 144, 9, 1),
	600: rgba(220, 104, 3, 1),
	700: rgba(181, 71, 8, 1),
	800: rgba(147, 55, 13, 1),
	900: rgba(122, 46, 14, 1),
	contrast: (
		50: $dark-accent-text,
		100: $dark-accent-text,
		200: $dark-accent-text,
		300: $light-accent-text,
		400: $light-accent-text,
		500: $light-accent-text,
		600: $dark-accent-text,
		700: $light-accent-text,
		800: $light-accent-text,
		900: $light-accent-text,
	),
);

$pangea-warn: (
	50: rgba(254, 243, 242, 1),
	100: rgba(254, 228, 226, 1),
	200: rgba(254, 205, 202, 1),
	300: rgba(253, 162, 155, 1),
	400: rgba(249, 112, 102, 1),
	500: rgba(240, 68, 56, 1),
	600: rgba(217, 45, 32, 1),
	700: rgba(180, 35, 24, 1),
	800: rgba(145, 32, 24, 1),
	900: rgba(122, 39, 26, 1),
	contrast: (
		50: $dark-warn-text,
		100: $dark-warn-text,
		200: $dark-warn-text,
		300: $light-warn-text,
		400: $light-warn-text,
		500: $light-warn-text,
		600: $dark-warn-text,
		700: $light-warn-text,
		800: $light-warn-text,
		900: $light-warn-text,
	),
);

$web-primary: mat.m2-define-palette($pangea-primary, 600, 100, 800);
$web-accent: mat.m2-define-palette($pangea-accent, 600);
$web-warn: mat.m2-define-palette($pangea-warn, 600, 50, 700);

$web-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $web-primary,
			accent: $web-accent,
			warn: $web-warn,
		),
	)
);

@include mat.all-component-themes($web-theme);

// Fix checkbox color of selected item.
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
	color: var(--primary-color-600);
}

pngw-sign-tag-edit-dialog .mat-mdc-dialog-content {
	max-height: 80vh;
}

:root {
	--mat-full-pseudo-checkbox-selected-icon-color: var(--primary-color-600);
	--mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-color-600);
	--mat-dialog-container-max-width: calc(100vw - 20px);
}
