:root {
	/*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */
	--font-fallback: -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Segoe UI", Oxygen, Ubuntu, Cantarell,
		"Open Sans", "Helvetica Neue", sans-serif;
	--font-family: Inter, var(--font-fallback);

	--base-color: rgba(0, 0, 0, 1);
	--text-color: rgba(13, 18, 28, 1);
	--base-contrast-color: rgba(255, 255, 255, 1);

	--primary-color-25: rgba(245, 250, 255, 1);
	--primary-color-50: rgba(239, 248, 255, 1);
	--primary-color-100: rgba(209, 233, 255, 1);
	--primary-color-200: rgba(178, 221, 255, 1);
	--primary-color-300: rgba(132, 202, 255, 1);
	--primary-color-400: rgba(83, 177, 253, 1);
	--primary-color-500: rgba(46, 144, 250, 1);
	--primary-color-600: rgba(21, 112, 239, 1);
	--primary-color-700: rgba(23, 92, 211, 1);
	--primary-color-800: rgba(24, 73, 169, 1);
	--primary-color-900: rgba(25, 65, 133, 1);
	--primary-dark-color-text: rgba(23, 92, 211, 1);
	--primary-light-color-text: var(--base-contrast-color);

	--neutral-color-50: rgba(248, 250, 252, 1);
	--neutral-color-100: rgba(238, 242, 246, 1);
	--neutral-color-200: rgba(227, 232, 239, 1);
	--neutral-color-700: rgba(54, 65, 82, 1);
	--neutral-dark-text-color: rgba(105, 117, 134, 1);

	--accent-color: rgba(220, 104, 3, 1);
	--accent-dark-text-color: rgba(181, 71, 8, 1);
	--accent-light-text-color: var(--base-contrast-color);

	--warn-color: rgb(228, 0, 45);
	--warn-color-50: rgba(254, 243, 242, 1);
	--warn-color-100: rgb(254, 228, 226);
	--warn-color-200: rgba(254, 205, 202, 1);
	--warn-color-500: rgba(240, 68, 56, 1);
	--warn-dark-text-color: rgba(180, 35, 24, 1);
	--warn-light-text-color: var(--base-contrast-color);

	--success-color-100: rgb(185, 232, 210, 1);
	--success-color-500: rgba(23, 178, 106, 1);
	--success-color-600: rgba(7, 148, 85, 1);
	--success-color-700: rgba(6, 118, 71, 1);

	--disabled-color: rgba(0, 0, 0, 0.38);

	--border-radius: 8px;
	--font-size-small: 12px;
	--font-size: 14px;
	--font-size-large: 16px;
	--font-size-very-large: 24px;
	--line-height: 24px;

	--aside-layout-width: 280px;
	--collapsed-aside-layout-width: 80px;
	--main-section-padding: 24px;

	--navigation-tabs-height: 64px;
	--paginator-height: 60px;
	--main-gap: 16px;
	--announcement-height: 40px;
}
