h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

h1 {
	font-size: 24px;
}

h2 {
	font-size: 20px;
}
