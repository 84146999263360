:root {
	--mat-standard-button-toggle-selected-state-background-color: var(--base-contrast-color);
	--mat-standard-button-toggle-height: 32px;
	--mat-standard-button-toggle-shape: var(--border-radius);
	--mat-standard-button-toggle-divider-color: none;
	--mat-standard-button-toggle-background-color: transparent;
}

.mat-button-toggle-group {
	gap: 5px;
	padding: 5px;
	background-color: var(--neutral-color-100);
}

.mat-button-toggle {
	width: 100%;
	overflow: hidden;
	border-radius: var(--border-radius);
}

.mat-button-toggle.mat-button-toggle-checked {
	box-shadow:
		0 1px 2px 0 rgba(18, 25, 38, 0.06),
		0 1px 3px 0 rgba(18, 25, 38, 0.08);
}

.hidden-toggle-group.mat-button-toggle-group-appearance-standard.mat-button-toggle-group-appearance-standard {
	border: none;
}

.hidden-toggle-group .mat-button-toggle {
	border: 1px solid var(--neutral-color-100);
	display: flex;
	width: auto;
	align-items: center;
}

.hidden-toggle-group.mat-button-toggle-group {
	gap: 5px;
	height: 40px;
	padding: 0;
	background-color: var(--base-contrast-color);
	--mat-standard-button-toggle-divider-color: var(--neutral-color-100);
	--mat-standard-button-toggle-selected-state-background-color: var(--neutral-color-200);
}
