.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
	--mat-tab-header-active-label-text-color: var(--base-color);
	--mat-tab-header-active-hover-label-text-color: var(--base-color);
	--mat-tab-header-active-focus-label-text-color: var(--base-color);
	--mat-tab-header-active-hover-indicator-color: var(--primary-color-500);
	--mat-tab-header-active-focus-indicator-color: var(--primary-color-500);
	--mat-tab-header-inactive-label-text-color: var(--base-color);
	--mat-tab-header-inactive-hover-label-text-color: var(--base-color);
	--mat-tab-header-inactive-focus-label-text-color: var(--base-color);
	--mdc-tab-indicator-active-indicator-color: var(--primary-color-500);

	padding-bottom: 16px;
}

.mat-mdc-tab-header {
	max-width: fit-content;
}

.mdc-tab.mat-mdc-tab-link {
	border-radius: var(--border-radius);
	overflow: hidden;
}
