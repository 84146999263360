input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input:not([type]),
textarea {
	width: 100%;
	font: inherit;
	color: inherit;
	border: none;
	outline: none;
	background: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
	appearance: none;
}

textarea {
	resize: none;
}
