.pngc-skeleton {
	border-radius: 3px;
	background: linear-gradient(
		-45deg,
		var(--neutral-color-50),
		var(--neutral-color-200),
		var(--neutral-color-100),
		var(--neutral-color-100)
	);
	background-size: 400% 400%;
	animation: skeleton-gradient-move 3s ease-in-out infinite;
}

@keyframes skeleton-gradient-move {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

h1.pngc-skeleton::before,
h2.pngc-skeleton::before,
h3.pngc-skeleton::before,
h4.pngc-skeleton::before,
h5.pngc-skeleton::before,
h6.pngc-skeleton::before,
p.pngc-skeleton::before,
span.pngc-skeleton::before,
time.pngc-skeleton::before,
a.pngc-skeleton::before {
	/* To emulate height of text. */
	content: ".";
	display: inline-block;
	color: transparent;
}
